<template>
  <v-card>
    <v-card-title>売上登録</v-card-title>
    <v-form ref="form">
      <v-card-text>
        <v-container>
          <v-row justify="center">
            <v-col md="9">
              <ctk-date-time-picker v-model="salesDatetime" :format="dateFormat" :overlay="true" label="売上日時*" :rules="rules.notNull">
                <template v-slot:append-outer>
                  <v-text-field v-model="salesDatetime" single-line label="売上日時">
                  </v-text-field>
                </template>
              </ctk-date-time-picker>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col md="9">
              <ctk-date-time-picker v-model="putUpDatetime" :format="dateFormat" :overlay="true" label="出品日時*" >
                <template v-slot:append-outer>
                  <v-text-field v-model="putUpDatetime" single-line label="出品日時" :rules="rules.notNull">
                  </v-text-field>
                </template>
              </ctk-date-time-picker>
            </v-col>
          </v-row>
          <v-row dense justify="center">
            <v-col md="3">
              <v-select outlined label="サービス名*" item-text="serviceName" item-value="serviceId" :items="servicesCache" v-model.trim="serviceObj" :rules="rules.notNull" return-object />
            </v-col>
            <v-col md="6">
              <v-text-field outlined v-model.trim="salesUserName" label="売上者氏名*" :rules="rules.notNull" />
            </v-col>
          </v-row>
        </v-container>
        <v-row no-gutters justify="center">
          <v-col md="9">
            <v-autocomplete :class="longInputClass" outlined label="商品名*" :items="itemStocksCache" item-text="uniqueItemName" item-value="itemId" v-model.trim="salesItemId" :search-input.sync="salesUniqueItemName" :rules="rules.notNull" />
          </v-col>
        </v-row>
        <v-row dense justify="center">
          <v-col md="3" cols="9">
              <v-text-field readonly outlined v-model.trim="salesItemCd" label="商品コード(自動入力)" />
          </v-col>
          <v-col md="3" cols="9">
              <v-text-field readonly outlined v-model.trim="itemSize" label="サイズ(自動入力)" />
          </v-col>
          <v-col md="3" cols="9">
              <v-text-field readonly outlined height=2 v-model.trim="itemColor" label="色(自動入力)" />
          </v-col>
        </v-row>
        <v-container>
          <v-row no-gutters justify="center">
            <v-col md="9">
              <v-select :class="longInputClass" outlined v-model.trim="shippingMethodId" label="発送方法*(自動入力、編集可能)" :items="shippingMethodsOnThisServiceCache" item-text="shippingMethodName" item-value="shippingMethodId" :rules="rules.notNull" />
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-col md="5" cols="12">
              <v-textarea outlined :disabled="addrDisabled" :filled="addrDisabled" v-model.trim="shippingToAddress" label="発送先住所氏名*（クリックポストの場合記入。「〒」なし、「様」なし、空行なし）" :rules="[val => (addrDisabled || !!val && val !== '' || '入力してください')]" />
            </v-col>
            <v-col md="4" cols="12">
              <v-file-input show-size :disabled="qrDisabled" :filled="qrDisabled" outlined accept="image/*" label="QRコード*" prepend-icon="mdi-qrcode-scan" @change="onFileUpload" :rules="[val => (qrDisabled || !!val && val !== '' || 'QRコード画像をアップロードしてください')]">
                <template v-slot:selection="{ text }">
                  <v-chip
                    small
                    label
                    color="primary"
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-col md="9">
              <v-text-field outlined v-model.trim="shippingMethodNote" label="備考（標準の発送方法以外をとる理由・購入者のリクエストなど）" />
            </v-col>
          </v-row>
        </v-container>
        <v-row justify="center">
          <v-col md="2">
          </v-col>
          <v-col md="2">
          </v-col>
          <v-col md="2">
            <v-select outlined v-model.trim="reservedSaleFlg" label="予約販売*" item-text="name" item-value="value" :items="reservedSaleFlgItems" />
          </v-col>
          <v-col md="3" cols="9">
            <v-select outlined v-model.trim="dealStatusType" label="取引ステータス*" item-text="name" item-value="value" :items="dealStatusTypeList" :rules="rules.notNull" />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="2" cols="12">
            <v-text-field outlined v-model.trim="itemPrice" label="販売価格*" :rules="rules.notNullPosInt" />
          </v-col>
          <v-col md="3" cols="12">
            <v-text-field readonly outlined v-model.trim="salesPrice" label="売上価格(自動入力)" :rules="rules.notNullPosInt" />
          </v-col>
          <v-col md="2" cols="12">
            <v-text-field readonly outlined v-model.trim="sellerShippingFee" label="販売者送料(自動入力)" :rules="rules.nonNegInt" />
          </v-col>
          <v-col md="2" cols="12">
            <v-text-field readonly outlined v-model.trim="shipperShippingFee" label="発送者送料(自動入力)" :rules="rules.nonNegInt" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
    <v-card-actions>
      <v-container><v-row justify="center"><v-col md="8">
          <v-btn height="100" block :disabled="isLoading" @click="submit">登録</v-btn>
      </v-col></v-row></v-container>
    </v-card-actions>
    <v-snackbar color="success" v-model="success" timeout=10000 right multi-line block>登録成功!
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="success = false"
        >
          ×
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar color="error" timeout=10000 v-model="hasError" right multi-line block>{{errMessage}}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="hasError = false"
        >
          ×
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
  //import DateTimePicker from "./../components/DateTimePicker";
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import CtkDateTimePicker from 'vue-ctk-date-time-picker'
  import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
  import DealStatusType from '../consts/deal_status_type.js'
  import ValidationRules from '../consts/validation_rules.js'
  import firebase from 'firebase/app'
  import Endpoints from '../consts/api_endpoint_map.js'

  export default {
    name: "RegisterSales",
    components: {
      // DateTimePicker
      CtkDateTimePicker,
    },
    data () {
      return {
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        // 初期値。
        salesDatetime: null,
        putUpDatetime: null,
        serviceObj: null,
        serviceId: null, // init in created lifecycle
        serviceName: null,
        salesUserName: null,
        salesItemId: null, // init in created lifecycle
        salesItemCd: null,
        salesUniqueItemName: null,
        itemSize: null,
        itemColor: null,
        shippingMethodId: null,
        shippingToAddress: null,
        shippingMethodNote: null,
        reservedSaleFlg: null,
        dealStatusType: null, // 発送待ち
        itemPrice: null,
        sales: {},
        success: false,
        hasError: false,
        errMessage: "",
        // 選択肢の定数値
        reservedSaleFlgItems: [{name:"-", value:"0"}, {name:"〇", value:"1"}],
        // キャッシュデータ
        itemStocksCache: [],
        itemStocksMapCache: {},
        servicesCache: [],
        servicesMapCache: {},
        itemShippingMethodsMapCache: {},
        shippingMethodsCache: [],
        shippingMethodsMapCache: {},
        // upload
        fileLoading: 0,
        qrImageUrl: "",
        // 区分値
        // 商品選択： itemId -> item
        // サービス選択： serviceId -> itemId -> shippingMethod
        // 発送方法選択： shippingMethodId -> shippingMethod qr or address, price
        dealStatusTypeList: DealStatusType,
        rules: ValidationRules,
        // loading control
        isLoading: false,
        // for css
        longInputClass: "",
      }
    },
    computed: {
      salesPrice: {
        get: function(){
          if(this.itemPrice){
            const commissionRate = this.servicesMapCache[this.serviceId]["salesCommissionRate"]
            return this.itemPrice - Math.floor(this.itemPrice * commissionRate)
          }
          return ""
        },
        // when reset
        set: function(val){
          return val
        }
      },
      sellerShippingFee: {
        get: function(){
          let ssfdata = this.shippingMethodsMapCache[this.shippingMethodId]
          let ssf = ""
          if(ssfdata){
            ssf = ssfdata["sellerShippingFee"]
          }
          return ssf
        },
        // when reset
        set: function(val){
          return val
        }        
      },
      shipperShippingFee: {
        get: function(){
          let ssfdata = this.shippingMethodsMapCache[this.shippingMethodId]
          let ssf = ""
          if(ssfdata){
            ssf = ssfdata["shipperShippingFee"]
          }
          return ssf
        },
        // when reset
        set: function(val){
          return val
        }
      },
      shippingMethodName: {
        get: function(){
          const rec = this.itemShippingMethodsMapCache[this.salesItemId + "_" + this.serviceId]
          if(rec){
            return rec["shippingMethodName"]
          }
          return ""
        },
        // when reset
        set: function(val){
          return val
        }
      },
      shippingMethodsOnThisServiceCache: {
        get: function(){
          let list = []
          for(const val of this.shippingMethodsCache){
            if(val["serviceId"] === this.serviceId){
              list.push(val)
            }
          }
          return list
        },
        // when reset
        set: function(val){
          return val
        }
      },
      addrDisabled: {
        get: function(){
          if(!this.shippingMethodId){
            return false
          }
          if(this.shippingMethodsMapCache[this.shippingMethodId]["shippingDesignateMethodType"] === "01"){
            return false
          }
          return true
        }
      },
      qrDisabled: {
        get: function(){
          return !this.addrDisabled
        }
      },
      ...mapGetters([
        'userName',
        'userId'
      ])
    },
    watch: {
      salesDatetime: function(salesDatetime){
        this.putUpDatetime = this.getLatestStandardPutUpDatetime(salesDatetime)
      },
      salesItemId: function(salesItemId){
        if(!salesItemId || !this.itemStocksMapCache[salesItemId]){
          return
        }
        this.salesItemCd = this.itemStocksMapCache[salesItemId]["itemCd"]
        this.itemSize = this.itemStocksMapCache[salesItemId]["size"]
        this.itemColor = this.itemStocksMapCache[salesItemId]["color"]
        this.itemPrice = this.itemStocksMapCache[salesItemId]["sellingPrice"]
        if(!this.serviceId){
          return
        }
        // when serviceId and salesitemId has set, the cache must have shippingMethod
        this.shippingMethodId = this.itemShippingMethodsMapCache[salesItemId + "_" + this.serviceId]["shippingMethodId"]
      },
      serviceObj: function(serviceObj){
        if(!serviceObj){
          return
        }
        this.serviceId = serviceObj["serviceId"]
        this.serviceName = serviceObj["serviceName"]
        if(!this.salesItemId || !this.itemShippingMethodsMapCache[this.salesItemId + "_" + this.serviceId]){
          return
        }
        this.shippingMethodId = this.itemShippingMethodsMapCache[this.salesItemId + "_" + this.serviceId]["shippingMethodId"]
      }
    },
    async created () {
      // スマホの場合にのみ文字列が長い v-select の文字サイズを縮小するcssを適用させる
      if(this.$vuetify.breakpoint.name === "xs"){
        this.longInputClass = "long-input-activated"
      }
      // マスタキャッシュデータの取得。初期データに基づく連動プルダウンの設定を行うため、 async await とする
      // 商品選択： itemId -> item
      // サービス選択： serviceId -> itemId -> shippingMethod
      // 発送方法選択： shippingMethodId -> shippingMethod qr or address, price
      // 商品一覧を取得して入力フォームの選択肢に埋め込む
      await axios.get(Endpoints.getCacheItemStocksEndpoint)
        .then(res => {
          this.itemStocksCache = res.data
        })
        .catch(err => {
          console.log(err)
        })
      await axios.get(Endpoints.getCacheItemStocksMapEndpoint)
        .then(res => {
          this.itemStocksMapCache = res.data
        })
        .catch(err => {
          console.log(err)
        })
      // サービス一覧を取得して入力フォームの選択肢に埋め込む
      await axios.get(Endpoints.getCacheServicesEndpoint)
        .then(res => {
          this.servicesCache = res.data
        })
        .catch(err => {
          console.log(err)
        })
      await axios.get(Endpoints.getCacheServicesMapEndpoint)
        .then(res => {
          this.servicesMapCache = res.data
        })
        .catch(err => {
          console.log(err)
        })
      // 発送方法一覧をサービスIDごとにまとめる
      await axios.get(Endpoints.getCacheShippingMethodsEndpoint)
        .then(res => {
          this.shippingMethodsCache = res.data
        })
        .catch(err => {
          console.log(err)
        })
      // 発送方法一覧
      await axios.get(Endpoints.getCacheShippingMethodsMapEndpoint)
        .then(res => {
          this.shippingMethodsMapCache = res.data
        })
        .catch(err => {
          console.log(err)
        })
      // 商品発送方法一覧を取得
      await axios.get(Endpoints.getCacheItemShippingMethodsMapEndpoint)
        .then(res => {
          this.itemShippingMethodsMapCache = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.init()
    },
    methods: {
      numPad(num, len) {
        return ("0".repeat(len) + num).slice(-len)
      },
      getCurrentYYYYMMDDHHMISS() {
          const date = new Date()
          // console.log(date.getFullYear() )
          // console.log(date.getMonth() )
          // console.log(date.getDate() )
          // console.log(date.getHours() )
          // console.log(date.getMinutes() )
          // console.log(date.getSeconds() )
          return "" +
            this.numPad(date.getFullYear(), 4) +
            this.numPad(date.getMonth()+1, 2) + 
            this.numPad(date.getDate(), 2) + 
            this.numPad(date.getHours(), 2) + 
            this.numPad(date.getMinutes(), 2) +
            this.numPad(date.getSeconds(), 2)
      },
      dateToFormattedStr(date){
          return "" +
            this.numPad(date.getFullYear(), 4) + "-" +
            this.numPad(date.getMonth()+1, 2) +  "-" +
            this.numPad(date.getDate(), 2) + " " +
            this.numPad(date.getHours(), 2) + ":" +
            this.numPad(date.getMinutes(), 2) + ":" +
            this.numPad(date.getSeconds(), 2)
      },
      getCurrentFormattedDatetime() {
        // format : YYYY-MM-DD HH:mm:ss
        const date = new Date()
        return this.dateToFormattedStr(date)
      },
      getLatestStandardPutUpDatetime(date){
        // getCurrentFormattedDatetime で取得した形式のdate文字列を引数にとる
        const today = new Date(date.split(" ")[0])
        const hours = parseInt(date.split(" ")[1][0] + date.split(" ")[1][1], 10)
        // const minutes = parseInt(date.split(" ")[1][3] + date.split(" ")[1][4], 10)
        var retDate
        if(hours < 17){
          retDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()-1, 23, 30)
        } else if(hours < 20){
          retDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 17, 0)
        } else {
          retDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 20, 0)
        }
        return this.dateToFormattedStr(retDate)
      },
      getFileType(file) {
        const re = /(.+)\.(.+)/
        const name = file.name
        return name.replace(re, '$2')
      },
      onFileUpload(file) {
        // file : File API
        if(!file){
          // when reset
          return
        }
        const fileType = this.getFileType(file)
        if (!fileType) {
          console.log(`fileTypeの取得に失敗。file:${file}`)
          return
        }
        const uploadFileName = `${this.getCurrentYYYYMMDDHHMISS()}.${fileType}`
        console.log("uploading : " + uploadFileName)
        const key = `qr/${uploadFileName}`
        const storageRef = firebase.storage().ref(key)
        const uploadTask = storageRef.put(file)
        uploadTask.on('state_changed', 
          snapshot => {
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            this.fileLoading = percentage
          },
          err => {
            console.log(err)
          },
          () => {
            // uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            //   this.fileLoading = 0
            //   this.qrImageUrl = key
            // })
            this.fileLoading = 0
            this.qrImageUrl = key
            console.log("upload done")
          }
        )
      },
      init: function () {
        /* resetにも使うため、初期状態で値がないフィールドの値の削除も行う。 */
        // date
        this.salesDatetime = this.getCurrentFormattedDatetime()
        this.serviceObj = {serviceId: 1, serviceName: "メルカリ"},
        // and this.serviceId, this.serviceName
        // ユーザ情報を取得
        this.salesUserName = this.userName
        // 初期値駆動で関連をセットさせる
        this.salesItemId = 2
        // and this.salesItemCd, this.salesUniqueItemName, this.size, this.color, prices, and others
        this.reservedSaleFlg = "0"
        this.dealStatusType = "03"
        // remove values
        this.shippingToAddress = null
        this.qrImageUrl = null
        this.shippingMethodNote = null
        this.sales = {}
      },
      submit: function () {
        if(!this.$refs.form.validate()){
          return
        }
        // validate ctk-date-time-picker values
        if(!this.salesDatetime || !this.putUpDatetime){
          this.success = false
          this.hasError = true
          this.errMessage = "日付フィールドを入力してください"
          return 
        }
        // go submit!
        this.isLoading = true
        this.$emit("start-loading")
        const sales = {
          // salesId
          // salesBizDate
          salesDatetime: this.salesDatetime,
          // salesDay
          putUpDatetime: this.putUpDatetime,
          // putUpDay
          salesServiceId: this.serviceId,
          salesServiceName: this.serviceName,
          // salesUserId
          salesUserName: this.salesUserName,
          salesItemId: this.salesItemId,
          salesUniqueItemName: this.salesUniqueItemName,
          itemSize: this.itemSize,
          itemColor: this.itemColor,
          shippingMethodType: (!this.addrDisabled ? "01" : "02"),
          shippingToAddress: this.shippingToAddress,
          qrImageUrl: this.qrImageUrl,
          shippingMethodId: this.shippingMethodId,
          // shippingFromUserId
          shippingMethodNote: this.shippingMethodNote,
          reservedSaleFlg: this.reservedSaleFlg,
          dealStatusType: this.dealStatusType,
          itemPrice: this.itemPrice,
          salesPrice: this.salesPrice,
          sellerShippingFee: this.sellerShippingFee,
          shipperShippingFee: this.shipperShippingFee
        }
        axios.post(Endpoints.registerSalesEndpoint, sales)
          .then(res => {
            console.log("received response from sales register")
            console.log(res)
            if(!res.data.hasError){
              this.success = true
              this.hasError = false
              this.errMessage = ""
              // フォームをreset
              // このとき this.$form.reset() 直後に init() しても効かない。また、バリデーションはこの処理中は必ずすべて通過済みのためバリデーションのリセットも不要
              this.init()
            } else {
              this.success = false
              this.hasError = true
              this.errMessage = res.data.errCode + "\n" + res.data.errMessage
            }
            this.isLoading = false
            this.$emit("end-loading")
          })
          .catch(err => {
            console.log("err:", err)
            this.success = false
            this.hasError = true
            this.errMessage = err
            this.isLoading = false
            this.$emit("end-loading")
          })
      }
    }
  }
</script>

<style>
  /* iPhoneだと縮小された文字に勝手にフォーカスするのでautocompleteはやめる */
  /*.long-input input {*/
    /*font-size: 1.8vmin;*/
    /*font-size: 0.8vw*/
  /*}*/
  /* font-size はそのままにして scale で表示だけを小さくすることで、iPhoneでズームさせないようにする → 左端も右にずれるので意味がない */
  /*.long-input .v-select__selection{*/
    /*transform: scale(0.6);*/
  /*}*/
  .long-input-activated .v-select__selection{
    font-size: 3.2vw
  }
/*  .v-list-item__title{
    transform: scale(0.8);
  }*/
</style>
